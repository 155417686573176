import React from 'react';
import { Link } from "react-router-dom";
import 'isomorphic-fetch';

class Contact extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            submitting: false,
            submitted: false,
            buttonState: '',
            name: '',
            email: '',
            phone: '',
            text: '',
        };
    }

    static defaultProps = {
        center: {
            lat: 59.95,
            lng: 30.33
        },
        zoom: 11
    };

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    onSubmit = (e) => {
        e.preventDefault();
        const data = {
            name: this.state.name,
            email: this.state.email,
            phone: this.state.phone,
            text: this.state.text,
        }
        fetch('/api/contact', {
            method: 'post',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(res => {
            if (res.status === 200) {
                this.setState({ submitted: true })
            }
            this.setState({name: '', email: '', phone: '', text: ''});
        });
    }

    onHideSuccess = () => {
        this.setState({submitted: false});
    }

    successMessage = () => {
        if (this.state.submitted){
            return (
                <div className="alert alert-success alert-dismissible fade show" style={{ marginTop: '14px' }}>
                    <strong>Gracias!</strong> Su mensaje se a enviado con exito.
                    <button
                        type="button"
                        className="close"
                        onClick={this.onHideSuccess}
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            );
        }
    }

    render(){
        return (
            <section id="contact" className="contact-area ptb-80 bg-f6f6f6">
                <div className="container">
                    <div className="section-title">
                        <h2><span>Contacto</span></h2>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 col-md-6">
                            <div className="contact-form">
                                <form id="contactForm" onSubmit={this.onSubmit}>
                                    <div className="row">
                                        <div className="col-lg-12 col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="name">Nombre</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="name"
                                                    id="name"
                                                    required
                                                    data-error="Please enter your name"
                                                    onChange={this.onChange}
                                                    value={this.state.name}
                                                />
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="email">E-mail</label>
                                                <input
                                                    type="email"
                                                    className="form-control"
                                                    name="email"
                                                    id="email"
                                                    required
                                                    data-error="Please enter your email"
                                                    value={this.state.email}
                                                    onChange={this.onChange}
                                                />
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-12">
                                            <div className="form-group">
                                                <label htmlFor="phone">Teléfono</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="phone"
                                                    id="phone"
                                                    required
                                                    data-error="Please enter your number"
                                                    value={this.state.phone}
                                                    onChange={this.onChange}
                                                />
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-12">
                                            <div className="form-group">
                                                <label htmlFor="text">Mensaje</label>
                                                <textarea
                                                    name="text"
                                                    className="form-control"
                                                    id="text"
                                                    cols="30"
                                                    rows="4"
                                                    required
                                                    data-error="Write your message"
                                                    value={this.state.text}
                                                    onChange={this.onChange}
                                                />
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-12">
                                            <button type="submit" className="btn btn-primary">Enviar</button>
                                            {this.successMessage()}
                                            <div className="clearfix"></div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <br/>
                                        <div className="row">
                        <div className="col-lg-6 col-md-6">
                            <div className="contact-info">
                                <ul>
                                    <li>
                                        <i className="fa fa-envelope"></i>
                                        <Link to="#">mlabra@decoracionycortinas.cl</Link>
                                    </li>
                                    <li>
                                        <i className="fa fa-phone"></i>
                                        <Link to="#">(+569) 9530-1534</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <div className="contact-info">
                                <ul>
                                    <li>
                                        <i className="fa fa-envelope"></i>
                                        <Link to="#">cgonzalez@decoracionycortinas.cl</Link>
                                    </li>
                                    <li>
                                        <i className="fa fa-phone"></i>
                                        <Link to="#">(+569) 8862-4148</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Contact;