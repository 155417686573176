import React from 'react'
import MainBanner from '../PageBanners/MainBannerTwo';
import Cortinas from '../Common/Cortinas'
//import WorkCortinas from '../Common/WorkCortinas';
import Videos from '../Common/Videos';
import Restauracion from '../Common/Restauracion';
import Textil from '../Common/Textil';
import Contacto from '../Common/Contact';
//import Contac from '../Common/Contacto';
import Footer from '../Common/Footer';
import GoTop from '../Common/GoTop';
import Contact from '../Common/Contact';

class HomeTwo extends React.Component {
    render(){
        return (
            <React.Fragment>
                {/* Main Banner */}
                <MainBanner />
                {/* CORTINAS */}
                <Cortinas />
                {/* VIDEOS */}
                <Videos />
                {/* RESTAURACION */}
                <Restauracion />
                {/* TEXTIL */}
                <Textil />
                {/* Contacto */}
                <Contacto />
                {/* <Contac /> */}
                {/* Footer Area */}
                <Footer />
                <GoTop scrollStepInPx="50" delayInMs="16.66" />
            </React.Fragment>
        );
    }
}

export default HomeTwo;