import React from 'react';

import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import imgOne from '../../assets/images/Textil/textil-07.jpg';
import imgTwo from '../../assets/images/Textil/textil-04.jpg';
import imgThree from '../../assets/images/Textil/textil-02.jpg';
import imgFour from '../../assets/images/Textil/FALDON_COLCHA-11.jpg';
import imgFive from '../../assets/images/Textil/textil-09.jpg';
import imgSix from '../../assets/images/Textil/textil-10.jpg';
import imgSeven from '../../assets/images/Textil/textil-03.jpg';
import imgEigth from '../../assets/images/Textil/textil-08.jpg';
import imgNine from '../../assets/images/Textil/textil-06.jpg';
import imgTen from '../../assets/images/Textil/aromatizante.jpg';

const data = [
    {
        id: 'tab1',
        id_cont_text: 'saba',
        name: 'tabset1',
        name_text: 'Sábanas'
    },
    {
        id: 'tab2',
        id_cont_text: 'cubreca',
        name: 'tabset1',
        name_text: 'Cubre Camas'
    },
    {
        id: 'tab3',
        id_cont_text: 'plumon',
        name: 'tabset1',
        name_text: 'Plumón de Plumas'
    },
    {
        id: 'tab4',
        id_cont_text: 'funda',
        name: 'tabset1',
        name_text: 'Colchas, Faldón y Fundas'
    },
    {
        id: 'tab5',
        id_cont_text: 'cubreco',
        name: 'tabset1',
        name_text: 'Cubre Colchón'
    },
    {
        id: 'tab6',
        id_cont_text: 'pie',
        name: 'tabset1',
        name_text: 'Pieceras'
    },
    {
        id: 'tab7',
        id_cont_text: 'almo',
        name: 'tabset1',
        name_text: 'Almohadas'
    },
    {
        id: 'tab8',
        id_cont_text: 'topper',
        name: 'tabset1',
        name_text: 'Topper'
    },
    {
        id: 'tab9',
        id_cont_text: 'toa',
        name: 'tabset1',
        name_text: 'Toallas'
    }, 
    {
        id: 'tab10',
        id_cont_text: 'arom',
        name: 'tabset1',
        name_text: 'Aromatizantes'
    },     
]

class Restauracion extends React.Component {
    constructor(props){
        super(props)

        this.state = {
            dropDownOpen: false
        } 
    }

    openTabSection = (evt, tabNmae) => {
        let i, tabcontent, tablinks;
        tabcontent = document.getElementsByClassName("tab-panel1");
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].style.display = "none";
        }

        tablinks = document.getElementsByClassName("tablinks1");
        for (i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace(" checked", "");
        }

        document.getElementById(tabNmae).style.display = "block";
        evt.currentTarget.className += " checked";
    }

    toggle = () => {
        this.setState({ dropDownOpen: !this.state.dropDownOpen });
    }

    render(){
        return (
            <section id="textil" className="why-we-different1 ptb-80 bg-f6f6f6">
                <div className="container">
                    <div className="section-title">
                        <h2><span>Textil Hogar</span></h2>
                        {/* <p>Necesitas Fabricar, Tapizar o Restaurar algún tipo de silla, sillón, etc? ¡Nosotros lo hacemos por ti! Consulta por precios a nuestro correo o a nuestro whatsapp. Garantizamos nuestros servicios.</p> */}
                    </div>
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <div className="tabset1">
                            <div className="row">
                                <div className="col-lg-6 col-md-6">
                                    <Dropdown isOpen={this.state.dropDownOpen} toggle={this.toggle} >
                                        <DropdownToggle caret style={{ width: '100%', borderColor: '#6d1e37', borderWidth: 'thick' }} >
                                            Tipos de Textilería
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            {
                                                data.map((data, i) => {
                                                    return <DropdownItem
                                                        key={i}
                                                        onClick={(e) => this.openTabSection(e, data.id_cont_text)}
                                                    >
                                                        {data.name_text}
                                                    </DropdownItem>
                                                })
                                            }
                                        </DropdownMenu>
                                    </Dropdown>
                                </div>
                            </div>

                                {/* <label
                                    onClick={(e) => this.openTabSection(e, 'saba')}
                                    className="tablinks1 checked"
                                    name="tabset1"
                                    id="tab1"
                                    //aria-controls="speed-flexibility"
                                    htmlFor="tab1"
                                >
                                    Sábanas
                                </label>

                                <label
                                    onClick={(e) => this.openTabSection(e, 'cubreca')}
                                    className="tablinks1"
                                    name="tabset1"
                                    id="tab2"
                                    //aria-controls="profeesional-work"
                                    htmlFor="tab2"
                                >
                                    Cubre Camas
                                </label>

                                <label
                                    onClick={(e) => this.openTabSection(e, 'plumon')}
                                    className="tablinks1"
                                    name="tabset1"
                                    id="tab3"
                                    //aria-controls="profeesional-work"
                                    htmlFor="tab3"
                                >
                                    Plumón de Plumas
                                </label>

                                <label
                                    onClick={(e) => this.openTabSection(e, 'funda')}
                                    className="tablinks1"
                                    name="tabset1"
                                    id="tab4"
                                    //aria-controls="profeesional-work"
                                    htmlFor="tab4"
                                >
                                    Colchas, Faldón y Fundas
                                </label>

                                <label
                                    onClick={(e) => this.openTabSection(e, 'cubreco')}
                                    className="tablinks1"
                                    name="tabset1"
                                    id="tab5"
                                    //aria-controls="profeesional-work"
                                    htmlFor="tab5"
                                >
                                    Cubre Colchón
                                </label>

                                <label
                                    onClick={(e) => this.openTabSection(e, 'pie')}
                                    className="tablinks1"
                                    name="tabset1"
                                    id="tab6"
                                    //aria-controls="profeesional-work"
                                    htmlFor="tab6"
                                >
                                    Pieceras
                                </label>

                                <label
                                    onClick={(e) => this.openTabSection(e, 'almo')}
                                    className="tablinks1"
                                    name="tabset1"
                                    id="tab7"
                                    //aria-controls="profeesional-work"
                                    htmlFor="tab7"
                                >
                                    Almohadas
                                </label>

                                <label
                                    onClick={(e) => this.openTabSection(e, 'topper')}
                                    className="tablinks1"
                                    name="tabset1"
                                    id="tab8"
                                    //aria-controls="profeesional-work"
                                    htmlFor="tab8"
                                >
                                    Topper
                                </label>

                                <label
                                    onClick={(e) => this.openTabSection(e, 'toa')}
                                    className="tablinks1"
                                    name="tabset1"
                                    id="tab9"
                                    //aria-controls="profeesional-work"
                                    htmlFor="tab9"
                                >
                                    Toallas
                                </label>

                                <label
                                    onClick={(e) => this.openTabSection(e, 'arom')}
                                    className="tablinks1"
                                    name="tabset1"
                                    id="tab10"
                                    //aria-controls="profeesional-work"
                                    htmlFor="tab10"
                                >
                                    Aromatizantes
                                </label> */}

                                <div className="tab-panels1">

                                    <section id="saba" className="tab-panel1" style={{display: "block"}}>
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6">
                                                <div className="why-we-different-text1">
                                                    <h4>En Decoración y Cortinas contamos con una amplia variedad en Sábanas, entre ellas:</h4>
                                                    <ul>
                                                        <li><i className="fa fa-check"></i>Sábanas Broderie</li>
                                                        <li><i className="fa fa-check"></i>Sábanas Bordadas</li>
                                                        <li><i className="fa fa-check"></i>Sábanas Lisas</li>
                                                        <li><i className="fa fa-check"></i>Sábanas Deocradas</li>
                                                    </ul>
                                                    <p>Tenemos en versiones desde 1 plaza hasta Super King.</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                <div className="why-we-different1-img">
                                                    <img src={imgOne} alt="img" />
                                                </div>
                                            </div>
                                        </div>
                                    </section>

                                    <section id="cubreca" className="tab-panel1">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6">
                                                <div className="why-we-different-text1">
                                                    <p>En Decoración y Cortinas tenemos una gran variedad de cubrecamas, divididos en las categorías de Quilt y Cobertores. Las diferencias están en la construcción o elaboración de cada una de estas piezas y sus características específicas. A grandes rasgos, todos abrigan en alguna medida y decoran la cama y dormitorio. Además, se pueden diferenciar algunos de invierno y otros de verano.</p>
                                                    <br/>
                                                    <h4>Tenemos en Quilt:</h4>
                                                    <ul>
                                                        <li><i className="fa fa-check"></i>Lisos, Bordados y decorados</li>
                                                        <li><i className="fa fa-check"></i>1 Plaza hasta Super King</li>
                                                    </ul>
                                                    <h4>Tenemos en Cobertores:</h4>
                                                    <ul>
                                                        <li><i className="fa fa-check"></i>Lisos y decorados</li>
                                                        <li><i className="fa fa-check"></i>1 Plaza hasta Super King</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                <div className="why-we-different1-img">
                                                    <img src={imgTwo} alt="img" />
                                                </div>
                                            </div>
                                        </div>
                                    </section>

                                    <section id="plumon" className="tab-panel1">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6">
                                                <div className="why-we-different-text1">
                                                    <p>La mayoría de las personas elige plumas porque son un aislante natural. Al ser huecas, cóncavas y sinuosas, forman un colchón de aire que almacena la temperatura corporal, evitando además el paso de humedad. Su característica de resiliencia (esponjosidad), liviandad y aislación térmica, las hacen perfectas para protegernos del frío.</p>
                                                    <br/>
                                                    <p>Disponible desde 1 Plaza hasta Super King.</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                <div className="why-we-different1-img">
                                                    <img src={imgThree} alt="img" />
                                                </div>
                                            </div>
                                        </div>
                                    </section>

                                    <section id="funda" className="tab-panel1">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6">
                                                <div className="why-we-different-text1">
                                                    <p>En Decoración y Cortinas tenemos una amplia variedad en Colchas, Faldones y Fundas, de gran calidad y durabilidad.</p>
                                                    <br/>
                                                    <h4>Colchas:</h4>
                                                    <ul>
                                                        <li><i className="fa fa-check"></i>Lisas</li>
                                                        <li><i className="fa fa-check"></i>Decoradas</li>
                                                    </ul>
                                                    <h4>Faldón:</h4>
                                                    <ul>
                                                        <li><i className="fa fa-check"></i>Broderie</li>
                                                        <li><i className="fa fa-check"></i>Bordados</li>
                                                        <li><i className="fa fa-check"></i>Lisas</li>
                                                        <li><i className="fa fa-check"></i>Decoradas</li>
                                                    </ul>
                                                    <h4>Faldón:</h4>
                                                    <ul>
                                                        <li><i className="fa fa-check"></i>Plumón</li>
                                                        <li><i className="fa fa-check"></i>Almohadas</li>
                                                        <li><i className="fa fa-check"></i>Cojines</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                <div className="why-we-different1-img">
                                                    <img src={imgFour} alt="img" />
                                                </div>
                                            </div>
                                        </div>
                                    </section>

                                    <section id="cubreco" className="tab-panel1">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6">
                                                <div className="why-we-different-text1">
                                                    <p>En Decoración y Cortinas Disponemos de Cubre colchones de algodón e impermeable</p>
                                                    <br/>
                                                    <p>El cubre colchón impermeable, tiene en su parte posterior una cubierta de poliuretano que evita la absorción de líquidos, logrando que se repelan de la superficie. De esta forma facilita su limpieza y al ser impermeable asegura una mayor vida útil al colchón.</p>
                                                    <br/>
                                                    <p>Disponible desde 1 Plaza hasta Super King.</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                <div className="why-we-different1-img">
                                                    <img src={imgFive} alt="img" />
                                                </div>
                                            </div>
                                        </div>
                                    </section>

                                    <section id="pie" className="tab-panel1">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6">
                                                <div className="why-we-different-text1">
                                                    <h4>En Decoración y Cortinas Tenemos una amplia variedad en pieceras de excelente calidad y diseño, entre ellas:</h4>
                                                    <ul>
                                                        <li><i className="fa fa-check"></i>Bordadas</li>
                                                        <li><i className="fa fa-check"></i>Mantas</li>
                                                        <li><i className="fa fa-check"></i>Lana</li>
                                                        <li><i className="fa fa-check"></i>Lisas</li>
                                                        <li><i className="fa fa-check"></i>Decoradas</li>
                                                    </ul>
                                                    <p>Tenemos en versiones desde 1 plaza hasta Super King.</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                <div className="why-we-different1-img">
                                                    <img src={imgSix} alt="img" />
                                                </div>
                                            </div>
                                        </div>
                                    </section>

                                    <section id="almo" className="tab-panel1">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6">
                                                <div className="why-we-different-text1">
                                                    <p>En Decoración y Cortinas tenemos almohadas viscoelásticas que están confeccionadas de un material sensible a la temperatura y totalmente adaptable a tu cabeza y cuello. Las almohadas viscoelásticas distribuyen el peso de manera uniforme otorgando alivio y comodidad.</p>
                                                    <br/>
                                                    <h4>También disponemos de almohadas:</h4>
                                                    <ul>
                                                        <li><i className="fa fa-check"></i>Pluma</li>
                                                        <li><i className="fa fa-check"></i>Fibra</li>
                                                        <li><i className="fa fa-check"></i>Algodón</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                <div className="why-we-different1-img">
                                                    <img src={imgSeven} alt="img" />
                                                </div>
                                            </div>
                                        </div>
                                    </section>

                                    <section id="topper" className="tab-panel1">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6">
                                                <div className="why-we-different-text1">
                                                    <p>En ocasiones, el colchón de la cama no ofrece todo el confort y el descanso que desean y necesitan los que descansan habitualmente en él. Pero tampoco se puede decir que se trata de un colchón incómodo. Por lo tanto, no es necesario cambiarlo, sino complementario. Para ello, basta con hacerse con un topper de cama. Este elemento permite mejorar su nivel de confortabilidad.</p>
                                                    <br/>
                                                    <p>Disponible desde 1 Plaza hasta Super King.</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                <div className="why-we-different1-img">
                                                    <img src={imgEigth} alt="img" />
                                                </div>
                                            </div>
                                        </div>
                                    </section>

                                    <section id="toa" className="tab-panel1">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6">
                                                <div className="why-we-different-text1">
                                                    <h4>En Decoración y Cortinas tenemos toallas de alta calidad, diseño y durabilidad, entre ellas:</h4>
                                                    <ul>
                                                        <li><i className="fa fa-check"></i>Toalla de bambú: Estas toallas poseen, a diferencia de muchas otras, propiedades anti bacterianas que las hacen extremadamente especiales debido al nivel de higiene que proveen a sus usuarios.</li>
                                                        <li><i className="fa fa-check"></i>Toalla de Algodón</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                <div className="why-we-different1-img">
                                                    <img src={imgNine} alt="img" />
                                                </div>
                                            </div>
                                        </div>
                                    </section>

                                    <section id="arom" className="tab-panel1">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6">
                                                <div className="why-we-different-text1">
                                                    <p>Contamos con productos de aromatización ambiental de alta calidad y con un servicio personalizado, responsable y profesional.</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                <div className="why-we-different1-img">
                                                    <img src={imgTen} alt="img" />
                                                </div>
                                            </div>
                                        </div>
                                    </section>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Restauracion;