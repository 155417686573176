import React from 'react';
import ModalVideo1 from 'react-modal-video';
import ModalVideo2 from 'react-modal-video';
import ModalVideo3 from 'react-modal-video';
import '../../../node_modules/react-modal-video/scss/modal-video.scss';
import { Link } from 'react-router-dom';
import videoImg1 from '../../assets/images/VideosImg/video1.jpg';
import videoImg2 from '../../assets/images/VideosImg/video2.jpg';
import videoImg3 from '../../assets/images/VideosImg/video3.jpg';

//https://www.youtube.com/watch?v=KIHCqdI_ats
//https://www.youtube.com/watch?v=f9Cu662Vjwo
//https://www.youtube.com/watch?v=9J2fm5K-2Uk

class Skill extends React.Component {
    state = {
        isOpen1: false,
        isOpen2: false,
        isOpen3: false
    }

    openModal1 = () => {
        this.setState({isOpen1: true})
    }

    openModal2 = () => {
        this.setState({isOpen2: true})
    }

    openModal3 = () => {
        this.setState({isOpen3: true})
    }

    render(){
        return (
            <section className="skill-area ptb-80">
                <div className="container">
                    <div className="section-title">
                        <h2><span>Trabajos Realizados</span></h2>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-12">
                            <div className="skill-video">
                                <img src={videoImg1} alt="video-img" />
                                <div className="video-btn">
                                    <Link
                                        onClick={e => {e.preventDefault(); this.openModal1()}}
                                        to="#"
                                        className="popup-youtube"
                                    >
                                        <i className="fa fa-play"></i>
                                    </Link>
                                    <ModalVideo1
                                        channel='youtube'
                                        isOpen={this.state.isOpen1}
                                        videoId='KIHCqdI_ats'
                                        onClose={() => this.setState({isOpen1: false})}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-12">
                            <div className="skill-video">
                                <img src={videoImg2} alt="video-img" />
                                <div className="video-btn">
                                    <Link
                                        onClick={e => {e.preventDefault(); this.openModal2()}}
                                        to="#"
                                        className="popup-youtube"
                                    >
                                        <i className="fa fa-play"></i>
                                    </Link>
                                    <ModalVideo2
                                        channel='youtube'
                                        isOpen={this.state.isOpen2}
                                        videoId='f9Cu662Vjwo'
                                        onClose={() => this.setState({isOpen2: false})}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-12">
                            <div className="skill-video">
                                <img src={videoImg3} alt="video-img" />
                                <div className="video-btn">
                                    <Link
                                        onClick={e => {e.preventDefault(); this.openModal3()}}
                                        to="#"
                                        className="popup-youtube"
                                    >
                                        <i className="fa fa-play"></i>
                                    </Link>
                                    <ModalVideo3
                                        channel='youtube'
                                        isOpen={this.state.isOpen3}
                                        videoId='9J2fm5K-2Uk'
                                        onClose={() => this.setState({isOpen3: false})}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Skill;