import React from 'react';
import imgRed from '../../assets/images/wpay-02.png'

class Footer extends React.Component {
    render(){
        return (
            <footer className="footer-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <ul>
                                <li><a href="https://www.facebook.com/decoracionycortinas.cl/" className="fa fa-facebook"></a></li>
                                <li><a href="https://www.instagram.com/decoracionycortinas.cl/" className="fa fa-instagram"></a></li>
                                <li><a href="https://www.youtube.com/channel/UCxuB1eEfZlHV8EP9kT2Rhyw/videos" className="fa fa-youtube"></a></li>
                            </ul>
                            <p>Copyright <i className="fa fa-copyright"></i> 2020 Decoración & Cortinas.</p>
                            <img src={imgRed} style={{ width: '300px', height: "90px" }} />
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;