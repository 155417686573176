import React from 'react';
import { Link } from 'react-router-dom';
import imgOne from '../../assets/images/Fabricacion/FABRICA.jpg';
import imgTwo from '../../assets/images/Fabricacion/TAPIZAO.jpg';

class Restauracion extends React.Component {

    openTabSection = (evt, tabNmae) => {
        let i, tabcontent, tablinks;
        tabcontent = document.getElementsByClassName("tab-panel");
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].style.display = "none";
        }

        tablinks = document.getElementsByClassName("tablinks");
        for (i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace(" checked", "");
        }

        document.getElementById(tabNmae).style.display = "block";
        evt.currentTarget.className += " checked";
    }

    render(){
        return (
            <section id="restauracion" className="why-we-different ptb-80 bg-f6f6f6">
                <div className="container">
                    <div className="section-title">
                        <h2><span>Fabricación, Tapizado y Restauración</span></h2>
                        <p>Necesitas Fabricar, Tapizar o Restaurar algún tipo de silla, sillón, etc? ¡Nosotros lo hacemos por ti! Consulta por precios a nuestro correo o a nuestro whatsapp. Garantizamos nuestros servicios.</p>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <div className="tabset">
                                {/* <!-- Tab 1 --> */}
                                <label
                                    onClick={(e) => this.openTabSection(e, 'fabricacion')}
                                    className="tablinks checked"
                                    name="tabset"
                                    id="tab1"
                                    //aria-controls="speed-flexibility"
                                    htmlFor="tab1"
                                >
                                    Fabricación
                                </label>
                                {/* <!-- Tab 2 --> */}
                                <label
                                    onClick={(e) => this.openTabSection(e, 'tapizado')}
                                    className="tablinks"
                                    name="tabset"
                                    id="tab2"
                                    //aria-controls="profeesional-work"
                                    htmlFor="tab2"
                                >
                                    Tapizado y Restauración
                                </label>

                                <div className="tab-panels">

                                    <section id="fabricacion" className="tab-panel" style={{display: "block"}}>
                                        <div className="row">
                                        <div className="col-lg-6 col-md-6">
                                            <div className="why-we-different-img">
                                                    <img src={imgOne} alt="img" />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                <div className="why-we-different-text">
                                                    <h4>¿Algo en mente? te podemos ayudar!</h4>
                                                    <ul>
                                                        <li><i className="fa fa-check"></i>Respaldos</li>
                                                        <li><i className="fa fa-check"></i>Sillas</li>
                                                        <li><i className="fa fa-check"></i>Poltrona</li>
                                                        <li><i className="fa fa-check"></i>Sitial</li>
                                                        <li><i className="fa fa-check"></i>Banqueta</li>
                                                        <li><i className="fa fa-check"></i>Digital</li>
                                                        <li><i className="fa fa-check"></i>Pouf</li>
                                                        <li><i className="fa fa-check"></i>Veladores</li>
                                                        <li><i className="fa fa-check"></i>Bases de cama</li>
                                                        <li><i className="fa fa-check"></i>Comodas</li>
                                                    </ul>
                                                    <p>Envíanos un mensaje y te respondemos a la brevedad.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </section>

                                    <section id="tapizado" className="tab-panel">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6">
                                                <div className="why-we-different-img">
                                                    <img src={imgTwo} alt="img" />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                <div className="why-we-different-text">
                                                    <h4>Si lo que necesitas es restaurar o tapizar, somos especialistas:</h4>
                                                    <ul>
                                                        <li><i className="fa fa-check"></i>Sillas</li>
                                                        <li><i className="fa fa-check"></i>Sofa</li>
                                                        <li><i className="fa fa-check"></i>Poltrona</li>
                                                        <li><i className="fa fa-check"></i>Sitial</li>
                                                        <li><i className="fa fa-check"></i>Banqueta</li>
                                                        <li><i className="fa fa-check"></i>Butacas</li>
                                                        <li><i className="fa fa-check"></i>Pouf</li>
                                                        <li><i className="fa fa-check"></i>Veladores</li>
                                                        <li><i className="fa fa-check"></i>Bases de cama</li>
                                                        <li><i className="fa fa-check"></i>Cómodas</li>
                                                    </ul>
                                                    <p>Envíanos un mensaje y te respondemos a la brevedad.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </section>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Restauracion;