import React from 'react';
import { Link } from 'react-router-dom';
import ModalService from './ModalServices';
import ImageGallery from 'react-image-gallery';

import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import '../../../node_modules/react-image-gallery/styles/css/image-gallery.css';

const data = [
	{
        "images" : [
            {
                original: require("../../assets/images/RollerBlackOut/rollerout1.jpg"),
                thumbnail: require("../../assets/images/RollerBlackOut/rollerout1.jpg"),
            },
            {
                original: require("../../assets/images/RollerBlackOut/rollerout2.jpg"),
                thumbnail: require("../../assets/images/RollerBlackOut/rollerout2.jpg"),
            },
            {
                original: require("../../assets/images/RollerBlackOut/rollerout3.jpg"),
                thumbnail: require("../../assets/images/RollerBlackOut/rollerout3.jpg"),
            },
            {
                original: require("../../assets/images/RollerBlackOut/rollerout4.jpg"),
                thumbnail: require("../../assets/images/RollerBlackOut/rollerout4.jpg"),
            },
            {
                original: require("../../assets/images/RollerBlackOut/rollerout5.jpg"),
                thumbnail: require("../../assets/images/RollerBlackOut/rollerout5.jpg"),
            },
            {
                original: require("../../assets/images/RollerBlackOut/rollerout6.jpg"),
                thumbnail: require("../../assets/images/RollerBlackOut/rollerout6.jpg"),
            },
            {
                original: require("../../assets/images/RollerBlackOut/rollerout7.jpg"),
                thumbnail: require("../../assets/images/RollerBlackOut/rollerout7.jpg"),
            }
            ,
            {
                original: require("../../assets/images/RollerBlackOut/rollerout8.jpg"),
                thumbnail: require("../../assets/images/RollerBlackOut/rollerout8.jpg"),
            }
        ],
		"titulo": "Roller BlackOut",
        "info": "Son la solución más práctica a la hora de oscurecer una estancia o incluso protegernos del sol cuando carecemos de persianas. Fabricados a medida, en diferentes materiales y con una amplia gama de colores, son sin duda el producto estrella.",
		"titulo_tab" : "Roller BlackOut",
        "id_tab": "tab1",
        "id_tab_text": "blackout",
        "style": "block",
        "cheked": "tablinks2 checked",
	},
	{
        "images" : [
            {
                original: require("../../assets/images/RollerSunsCreen/rollersuns1.jpg"),
                thumbnail: require("../../assets/images/RollerSunsCreen/rollersuns1.jpg"),
            },
            {
                original: require("../../assets/images/RollerSunsCreen/rollersuns2.jpg"),
                thumbnail: require("../../assets/images/RollerSunsCreen/rollersuns2.jpg"),
            },
            {
                original: require("../../assets/images/RollerSunsCreen/rollersuns3.jpg"),
                thumbnail: require("../../assets/images/RollerSunsCreen/rollersuns3.jpg"),
            },
            {
                original: require("../../assets/images/RollerSunsCreen/rollersuns4.jpg"),
                thumbnail: require("../../assets/images/RollerSunsCreen/rollersuns4.jpg"),
            },
            {
                original: require("../../assets/images/RollerSunsCreen/rollersuns5.jpg"),
                thumbnail: require("../../assets/images/RollerSunsCreen/rollersuns5.jpg"),
            },
            {
                original: require("../../assets/images/RollerSunsCreen/rollersuns6.jpg"),
                thumbnail: require("../../assets/images/RollerSunsCreen/rollersuns6.jpg"),
            }
        ],
        "titulo": "Roller SunsCreen",
        "info": "Es una de las soluciones más prácticas y modernas en lo que a roller se refiere. Disponemos de un amplio abanico de composiciones de tejidos (poliestér-PVC, Fibra de vidrio) para que a la hora de decidir comprar un roller de suncreen pueda elegir cuál de ellas se acerca más a sus necesidades.",
		"titulo_tab": "Roller SunsCreen",
        "id_tab": "tab2",
        "id_tab_text": "sunscreen",
        "style": "",
        "cheked": "tablinks2",
    },
	{
        "images" : [
            {
                original: require("../../assets/images/RollerDuo/rollerduo1.jpg"),
                thumbnail: require("../../assets/images/RollerDuo/rollerduo1.jpg"),
            },
            {
                original: require("../../assets/images/RollerDuo/rollerduo2.jpg"),
                thumbnail: require("../../assets/images/RollerDuo/rollerduo2.jpg"),
            },
            {
                original: require("../../assets/images/RollerDuo/rollerduo3.jpg"),
                thumbnail: require("../../assets/images/RollerDuo/rollerduo3.jpg"),
            }
        ],
        "titulo": "Roller Duo",
        "info": "Nos dan la posibilidad de elegir la entrada de luz que más nos apetezca en cada momento, este sistema de duo o noche y día tiene esta peculiaridad en su sistema de enrolle, superponiendo las franjas más opacas con las más traslúcidas y así conseguir el efecto deseado y graduando la entrada de luz.",
		"titulo_tab": "Roller Duo",
        "id_tab": "tab3",
        "id_tab_text": "duo",
        "style": "",
        "cheked": "tablinks2",
    },
	{
        "images" : [
            {
                original: require("../../assets/images/RollerPerso/rollerperso1.jpg"),
                thumbnail: require("../../assets/images/RollerPerso/rollerperso1.jpg"),
            },
            {
                original: require("../../assets/images/RollerPerso/rollerperso3.jpg"),
                thumbnail: require("../../assets/images/RollerPerso/rollerperso3.jpg"),
            },
            {
                original: require("../../assets/images/RollerPerso/rollerperso4.jpg"),
                thumbnail: require("../../assets/images/RollerPerso/rollerperso4.jpg"),
            },
            {
                original: require("../../assets/images/RollerPerso/rollerperso2.jpg"),
                thumbnail: require("../../assets/images/RollerPerso/rollerperso2.jpg"),
            },
            {
                original: require("../../assets/images/RollerPerso/rollerperso5.jpg"),
                thumbnail: require("../../assets/images/RollerPerso/rollerperso5.jpg"),
            },
            {
                original: require("../../assets/images/RollerPerso/rollerperso6.jpg"),
                thumbnail: require("../../assets/images/RollerPerso/rollerperso6.jpg"),
            }
        ],
        "titulo": "Roller Personalizado",
        "info": "Tenemos la solución para decorar la habitación. Te presentamos esta colección de roller estampados modernos, que le darán, ese toque de distinción que necesitas",
		"titulo_tab": "Roller Personalizado",
        "id_tab": "tab4",
        "id_tab_text": "personalizado",
        "style": "",
        "cheked": "tablinks2",
    },
	{
        "images" : [
            {
                original: require("../../assets/images/CortinasVeneciana/veneciana1.jpg"),
                thumbnail: require("../../assets/images/CortinasVeneciana/veneciana1.jpg"),
            },
            {
                original: require("../../assets/images/CortinasVeneciana/veneciana2.jpg"),
                thumbnail: require("../../assets/images/CortinasVeneciana/veneciana2.jpg"),
            },
            {
                original: require("../../assets/images/CortinasVeneciana/veneciana3.jpg"),
                thumbnail: require("../../assets/images/CortinasVeneciana/veneciana3.jpg"),
            }
        ],
        "titulo": "Cortinas Venecianas",
        "info": "Confeccionadas con materiales de primera calidad. Permiten graduar de forma eficaz la intensidad de luz aportando un toque moderno a las estancias.",
		"titulo_tab": "Cortinas Venecianas",
        "id_tab": "tab5",
        "id_tab_text": "venecianas",
        "style": "",
        "cheked": "tablinks2",
    },
	{
        "images" : [
            {
                original: require("../../assets/images/CortinasVerticales/verti3.jpg"),
                thumbnail: require("../../assets/images/CortinasVerticales/verti3.jpg"),
            }
        ],
        "titulo": "Cortinas Verticales BlackOut",
        "info": "Las cortinas de lamas verticales opacas o blackout, son la solución más práctica a la hora de oscurecer una estancia o incluso protegernos del sol cuando carecemos de persianas. Fabricados a medida, en diferentes materiales y con una amplia gama de colores, son sin duda el producto estrella.",
		"titulo_tab": "Cortinas Vertical BlackOut",
        "id_tab": "tab6",
        "id_tab_text": "verticalb",
        "style": "",
        "cheked": "tablinks2",
    },
    {
        "images" : [
            {
                original: require("../../assets/images/CortinasVerticales/verti1.jpg"),
                thumbnail: require("../../assets/images/CortinasVerticales/verti1.jpg"),
            },
            {
                original: require("../../assets/images/CortinasVerticales/verti2.jpg"),
                thumbnail: require("../../assets/images/CortinasVerticales/verti2.jpg"),
            }
        ],
        "titulo": "Cortinas Verticales SunsCreen",
        "info": "Las cortinas de lamas verticales de suncreen, son una de las soluciones más prácticas y modernas en lo que a Cortinas se refiere. Funcionales, de muy fácil limpieza y sin ningún tipo de mantenimiento. Este tejido además nos dará una alta protección solar si es lo que estamos buscando.",
		"titulo_tab": "Cortinas Vertical SunsCreen",
        "id_tab": "tab7",
        "id_tab_text": "verticals",
        "style": "",
        "cheked": "tablinks2",
    },
    {
        "images" : [
            {
                original: require("../../assets/images/Japones/japo1.jpg"),
                thumbnail: require("../../assets/images/Japones/japo1.jpg"),
            },
            {
                original: require("../../assets/images/Japones/japo2.jpg"),
                thumbnail: require("../../assets/images/Japones/japo2.jpg"),
            },
            {
                original: require("../../assets/images/Japones/japo3.jpg"),
                thumbnail: require("../../assets/images/Japones/japo3.jpg"),
            }
        ],
        "titulo": "Panel Japonés",
        "info": "Los paneles japoneses opacos o blackout, son la solución más práctica a la hora de oscurecer una estancia o incluso protegernos del sol cuando carecemos de persianas. Fabricados a medida, en diferentes materiales y con una amplia gama de colores, son sin duda el producto estrella.",
        "titulo_tab": "Panel Japonés",
        "id_tab": "tab8",
        "id_tab_text": "japones",
        "style": "",
        "cheked": "tablinks2",
    },
    {
        "images" : [
            {
                original: require("../../assets/images/CortinaTradi/Tradicionales_1.jpg"),
                thumbnail: require("../../assets/images/CortinaTradi/Tradicionales_1.jpg"),
            },
            {
                original: require("../../assets/images/CortinaTradi/Tradicionales_2.jpg"),
                thumbnail: require("../../assets/images/CortinaTradi/Tradicionales_2.jpg"),
            },
            {
                original: require("../../assets/images/CortinaTradi/Tradicionales_3.jpg"),
                thumbnail: require("../../assets/images/CortinaTradi/Tradicionales_3.jpg"),
            },
            {
                original: require("../../assets/images/CortinaTradi/Tradicionales_4.jpg"),
                thumbnail: require("../../assets/images/CortinaTradi/Tradicionales_4.jpg"),
            },
            {
                original: require("../../assets/images/CortinaTradi/Tradicionales_5.jpg"),
                thumbnail: require("../../assets/images/CortinaTradi/Tradicionales_5.jpg"),
            },
            {
                original: require("../../assets/images/CortinaTradi/Tradicionales_6.jpg"),
                thumbnail: require("../../assets/images/CortinaTradi/Tradicionales_6.jpg"),
            },
            {
                original: require("../../assets/images/CortinaTradi/Tradicionales_7.jpg"),
                thumbnail: require("../../assets/images/CortinaTradi/Tradicionales_7.jpg"),
            },
            {
                original: require("../../assets/images/CortinaTradi/Tradicionales_8.jpg"),
                thumbnail: require("../../assets/images/CortinaTradi/Tradicionales_8.jpg"),
            },
            {
                original: require("../../assets/images/CortinaTradi/Tradicionales_9.jpg"),
                thumbnail: require("../../assets/images/CortinaTradi/Tradicionales_9.jpg"),
            },
            {
                original: require("../../assets/images/CortinaTradi/Tradicionales_10.jpg"),
                thumbnail: require("../../assets/images/CortinaTradi/Tradicionales_10.jpg"),
            },
            {
                original: require("../../assets/images/CortinaTradi/Tradicionales_11.jpg"),
                thumbnail: require("../../assets/images/CortinaTradi/Tradicionales_11.jpg"),
            },
            {
                original: require("../../assets/images/CortinaTradi/Tradicionales_12.jpg"),
                thumbnail: require("../../assets/images/CortinaTradi/Tradicionales_12.jpg"),
            }
        ],
        "titulo": "Cortinas Tradicional",
        "info": "...",
        "titulo_tab": "Cortinas Tradicional",
        "id_tab": "tab9",
        "id_tab_text": "tradi",
        "style": "",
        "cheked": "tablinks2",
    },
    {
        "images" : [
            {
                original: require("../../assets/images/CortinasStore/Store_1.jpg"),
                thumbnail: require("../../assets/images/CortinasStore/Store_1.jpg"),
            },
            {
                original: require("../../assets/images/CortinasStore/Store_2.jpg"),
                thumbnail: require("../../assets/images/CortinasStore/Store_2.jpg"),
            },
            {
                original: require("../../assets/images/CortinasStore/Store_3.jpg"),
                thumbnail: require("../../assets/images/CortinasStore/Store_3.jpg"),
            },
            {
                original: require("../../assets/images/CortinasStore/Store_4.jpg"),
                thumbnail: require("../../assets/images/CortinasStore/Store_4.jpg"),
            },
            {
                original: require("../../assets/images/CortinasStore/Store_5.jpg"),
                thumbnail: require("../../assets/images/CortinasStore/Store_5.jpg"),
            }
        ],
        "titulo": "Cortinas Store",
        "info": "...",
        "titulo_tab": "Cortinas Store",
        "id_tab": "tab10",
        "id_tab_text": "store",
        "style": "",
        "cheked": "tablinks2",
    },
    {
        "images" : [
            {
                original: require("../../assets/images/CortinasRoman/Romantica_1.jpg"),
                thumbnail: require("../../assets/images/CortinasRoman/Romantica_1.jpg"),
            },
            {
                original: require("../../assets/images/CortinasRoman/Romantica_2.jpg"),
                thumbnail: require("../../assets/images/CortinasRoman/Romantica_2.jpg"),
            },
            {
                original: require("../../assets/images/CortinasRoman/Romantica_3.jpg"),
                thumbnail: require("../../assets/images/CortinasRoman/Romantica_3.jpg"),
            }
        ],
        "titulo": "Cortinas Románticas",
        "info": "...",
        "titulo_tab": "Cortinas Románticas",
        "id_tab": "tab11",
        "id_tab_text": "roman",
        "style": "",
        "cheked": "tablinks2",
    },
    {
        "images" : [
            {
                original: require("../../assets/images/Barras/Barras_1.jpg"),
                thumbnail: require("../../assets/images/Barras/Barras_1.jpg"),
            },
            {
                original: require("../../assets/images/Barras/Barras_2.jpg"),
                thumbnail: require("../../assets/images/Barras/Barras_2.jpg"),
            },
            {
                original: require("../../assets/images/Barras/Barras_3.jpg"),
                thumbnail: require("../../assets/images/Barras/Barras_3.jpg"),
            }
        ],
        "titulo": "Barras y accesorio",
        "info": "Fierro para Cortina de diámetro 25 milímetros, color Negro o Satín. Las medidas para este producto son: 1.50 metros - 2.00 metros - 2.50 metros - 3.00 metros, 3,5 metros. Soporte Doble 25 milímetros, Terminal Tope 25 milímetros,Terminal Bola Alambre 25 milímetros.",
        "titulo_tab": "Barras y accesorio para cortinas",
        "id_tab": "tab12",
        "id_tab_text": "barras",
        "style": "",
        "cheked": "tablinks2",
    },
]

class Cortinas extends React.Component {
    constructor(props){
        super(props)

        this.state = {
            dropDownOpen: false
        } 
    }

    openTabSection = (evt, tabNmae) => {
        let i, tabcontent, tablinks;

        tabcontent = document.getElementsByClassName("tab-panel2");
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].style.display = "none";
        }

        tablinks = document.getElementsByClassName("tablinks2");
        for (i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace(" checked", "");
        }

        document.getElementById(tabNmae).style.display = "block";
        evt.currentTarget.className += " checked";
    }

    toggle = () => {
        this.setState({ dropDownOpen: !this.state.dropDownOpen });
    }

    render(){
        return (
            <section id="cortinas" className="why-we-different2 ptb-80 bg-f6f6f6">
                <div className="container">
                    <div className="section-title">
                        <h2><span>Cortinas</span></h2>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <div className="tabset2">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6">
                                        <Dropdown isOpen={this.state.dropDownOpen} toggle={this.toggle} >
                                            <DropdownToggle caret style={{ width: '100%', borderColor: '#6d1e37', borderWidth: 'thick' }} >
                                                Lista de Cortinas
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                {
                                                    data.map((data, i) => {
                                                        return <DropdownItem
                                                            key={i}
                                                            onClick={(e) => this.openTabSection(e, data.id_tab_text)}
                                                        >
                                                            {data.titulo_tab}
                                                        </DropdownItem>
                                                    })
                                                }
                                            </DropdownMenu>
                                        </Dropdown>
                                    </div>
                                </div>
                                {/* {
                                    data.map((data, i) => {
                                        return <label
                                            onClick={(e) => this.openTabSection(e, data.id_tab_text)}
                                            className={data.cheked}
                                            name={data.id_tab_text}
                                            id={data.id_tab}
                                            key={i}
                                            htmlFor={data.id_tab}
                                        >
                                            {data.titulo_tab}
                                        </label>
                                    })
                                } */}
                                <div className="tab-panels2">
                                    {
                                        data.map((data, i) => {
                                            return <section id={data.id_tab_text} className="tab-panel2" style={{display: data.style}} key={i} >
                                                    <div className="row">
                                                        <div className="col-lg-6 col-md-6">
                                                            <div className="why-we-different-text1">
                                                                <h4>{data.titulo}</h4>
                                                                <p>{data.info}</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-6">
                                                            <div className="why-we-different2-img">
                                                                {/* <img src={data.img} alt="img" /> */}
                                                                <ImageGallery items={data.images} infinite={false} />
                                                            </div>
                                                        </div>
                                                    </div>
                                            </section>
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Cortinas;